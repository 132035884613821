import { Link, Head } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import { PageProps } from '@/types'
import { Button } from '@nextui-org/react'
import { ILandingPage } from '@/lib/contentful/interfaces/page'
// import LandingPage from "@/components/contentful/landing-page/landing-page";
import AppHead from '@/Layouts/app-head'
import { Suspense, lazy } from 'react'
import { Skeleton } from '@nextui-org/skeleton'

const LandingPage = lazy(
    () => import('@/components/contentful/landing-page/landing-page')
)

export default function Page ({
    auth,
    entry,
    previewMode,
    seo,
    ...rest
}: PageProps<{
    entry: ILandingPage
    previewMode: boolean
    seo: any
}>) {
    return (
        <>
            <Suspense
                fallback={
                    <div className='min-h-screen w-full flex items-center justify-items-center'>
                        <Skeleton className='rounded-lg'>
                            <div className='min-h-screen h-full rounded-lg bg-default-300'></div>
                        </Skeleton>
                    </div>
                }
            >
                {/* <pre className=''>{JSON.stringify(rest, null, 2)}</pre>
                ! */}
                <AppHead title={entry?.fields?.seoMetadata?.fields?.title} />
                {previewMode && (
                    <div className='w-full text-center text-white bg-red-300 p-6 shadow'>
                        You are in edit mode
                    </div>
                )}
                <div className='min-h-screen w-full '>
                    <LandingPage page={entry} isPreview={true} />
                </div>
            </Suspense>
        </>
    )
}
